<template>
  <div class="row mt-5">
    <div class="col-12 col-lg-5 mx-auto">
      <b-alert variant="danger" show style="display:none" :style="[error ? {'display':'block'} : {'display':'none'}]">{{error}}</b-alert>
      <div class="card">
        <article class="card-body">
          <!-- Login -->
            <h4 class="card-title text-center mb-4 mt-1">Iniciar sesión</h4>
            <hr />
            <p class="text-success text-center">Ingresa correo y contraseña</p>
            <form @submit.prevent="signIn">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <b-icon-person-fill></b-icon-person-fill>
                  </span>
                </div>
                <input
                  name=""
                  class="form-control"
                  placeholder="Email"
                  type="email"
                  v-model="credentials.correo"
                />
              </div>
              <!-- input-group.// -->
            </div>
            <!-- form-group// -->
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <b-icon-lock-fill></b-icon-lock-fill>
                  </span>
                </div>
                <input
                  class="form-control"
                  placeholder="******"
                  type="password"
                  v-model="credentials.contrasena"
                />
              </div>
              <!-- input-group.// -->
            </div>
              <!-- form-group// -->
              <div class="form-group">
                <button type="submit" class="btn btn-primary btn-block" :disabled="!isValid || loader">
                  <b-spinner small v-if="loader"></b-spinner>
                  Ingresar
                </button>
              </div>
              <!-- form-group// -->
              <p class="text-center">
                <router-link to="/forgot">¿Olvidaste la contraseña?</router-link>
              </p>
            </form>
        </article>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from 'vuex';
const regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  export default {
      name: 'Login',
      data: function(){
          return {
            credentials: {
              correo: '',
              contrasena: ''
            }
          }
      },
      computed: {
        ...mapState('users', ['error', 'loader']),
        ...mapState('assignments', ['interval']),
        isValid: function(){
          return regEx.test(this.credentials.correo) && this.credentials.contrasena.length > 0;
        }
      },
      methods: {
        ...mapActions('users', ['login']),
        ...mapMutations('assignments', ['setUnanouncedAssignments']),
        signIn: function(){
          this.login(this.credentials);            
        }
      },
      created: function () {
        this.setUnanouncedAssignments([]);
      }
  }
</script>